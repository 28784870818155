<template>
  <b-form>
    <b-row>
      <b-col cols="12">
        <b-button variant="primary" @click="$refs.refInputEl.click()">
          <input
            id="file1"
            ref="refInputEl"
            type="file"
            class="d-none"
            @input="add($event)"
          />
          <span class="d-none d-sm-inline">上传</span>
          <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
        </b-button>
        &nbsp;
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="info"
          @click="fileDel"
        >
          删除
        </b-button>
      </b-col>

      <!-- latest photo loop -->
      <b-col v-for="data in piclist" :key="data.picurl" style="width: 200px">
        <b-link @click="check(data)">
          <b-img
            fluid
            rounded
            :src="data.picurl"
            :alt="data.name"
            class="container"
          />
          <b-form-radio
            :id="'ck' + data.id"
            name="some-radios"
            class="checkbox"
            @change="changinfo(data)"
          >
          </b-form-radio>
        </b-link>
      </b-col>
      <!-- latest photo loop -->
    </b-row>
  </b-form>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import vSelect from "vue-select";
import {
  BButton,
  BPagination,
  BLink,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BListGroup,
  BListGroupItem,
  BDropdown,
  BModal,
  VBModal,
  BRow,
  BCol,
  BFormRadio,
  BFormFile,
} from "bootstrap-vue";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import useJwt from "@/auth/jwt/useJwt";
import base64 from "@/utils/base64";
import fetch from "@/utils/fetch";
export default {
  components: {
    BButton,
    BPagination,
    BListGroup,
    BListGroupItem,
    vSelect,
    BLink,
    BImg,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormFile,
    BFormInput,
    BFormSelect,
    BDropdownItem,
    BFormRadio,
    BDropdown,
    BModal,
    BCardCode,
    Prism,
    ToastificationContent,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      aid: 0,
      cid: 0,
      did: 0,
      selected: true,
      pageLength: 20,
      typename: "Picture",
      text: "",
      name: "",
      info: "",
      piclist: [],
      content: "",
      showUrl: "",
      picurl: "",
      name: "",
      selectgroup: "",
    };
  },
  props: {
    type: {
      type: Number,
      default: 0,
    },
  },

  created() {
    console.log("type", this.type);

    // if (this.type == 2) {
    //   this.$ref.refInputEl.setAttribute("accept", ".jpg, .png, .gif");
    // } else if (this.type == 3) {
    //   this.$ref.refInputEl.setAttribute("accept", ".avi, .mpg, .mpeg, .mp4");
    // }
    const user = JSON.parse(localStorage.getItem("userData"));
    this.cid = user.cid;
    useJwt
      .picpageList({
        cid: this.cid,
        type: this.type,
        page: 1,
        rows: this.pageLength,
      })
      .then((res) => {
        console.log("素材..", JSON.stringify(res));
        this.piclist = res.data.data.rows;
      });
  },
  mounted() {
    console.log("上传", this.$refs.refInputEl);
    var file = document.getElementById("file1");
    if (this.type == 2) {
      file.setAttribute("accept", ".jpg, .png, .gif");
    } else if (this.type == 3) {
      file.setAttribute("accept", ".avi, .mpg, .mpeg, .mp4");
    }
  },
  methods: {
    fileDel() {
      console.log("素材信息", this.info);
      useJwt
        .fileDel({
          id: this.info.id,
          path: this.info.path,
        })
        .then((res) => {
          this.$swal({
            icon: "success",
            title: "删除成功!",
            text: "删除成功",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        });
    },
    check(data) {
      this.info = data;
      console.log("信息", this.info);
      document.getElementById("ck" + data.id).checked = true;
      this.picurl = data.picurl;
      console.log(this.picurl);
      this.$emit("func", this.picurl);
    },
    async add(e) {
      const file = e.target.files[0];
      console.log("file", file);
      if (this.typename == "Picture" || this.typename == "Video") {
        const isLt2M = file.size / 1024 / 1024 < 10;
        if (!isLt2M) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: `上传文件大小不能超过 2MB!`,
                icon: "XCircleIcon",
                variant: "success",
                text: ``,
              },
            },
            {
              position: "top-center",
            }
          );
          return;
        }
        let formData = new FormData();
        formData.append("myfile", file);
        formData.append("isUploadFile", true);
        console.log("formData", formData);
        await fetch.fileUpload("/MaterialUpload", formData).then(({ data }) => {
          console.log("data", JSON.stringify(data));
          console.log(data.msg === "success");
          if (data.msg === "success") {
            this.showUrl = data.data.url;
            this.name = data.data.name;
          }
        });
      }
      await useJwt
        .picInsert({
          cid: this.cid,
          picurl: base64.encode(this.showUrl),
          name: this.name,
          type: this.type,
        })
        .then((res) => {
          console.log("res", JSON.stringify(res));
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: `上传成功!`,
                icon: "XCircleIcon",
                variant: "success",
                text: ``,
              },
            },
            {
              position: "top-center",
            }
          );
          useJwt
            .picpageList({
              cid: this.cid,
              type: this.type,
              page: 1,
              rows: this.pageLength,
            })
            .then((res) => {
              console.log("素材..", JSON.stringify(res));
              this.piclist = res.data.data.rows;
            });
        });

      //   this.$bvModal.hide("hua-shu");
    },
  },
};
</script>

<style>
.container {
  position: relative;
  width: 100px;
  height: 100px;
  float: left;
  margin-left: 10px;
}

.checkbox {
  position: absolute;
  bottom: 0px;
  right: 0px;
  left: 120px;
}
</style>