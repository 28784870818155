<template>
  <b-card-code title="微信列表">
    <!-- search input -->
    <upload @func="getMsgFormSon" :type="2"></upload>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BAvatar,
  BBadge,
  BButton,
  BPagination,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BImg,
  BModal,
  VBModal,
  BRow,
  BCol,
  BFormFile,
} from "bootstrap-vue";
import fetch from "@/utils/fetch";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import { VueGoodTable } from "vue-good-table";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import Prism from "vue-prism-component";
import store from "@/store/index";
import useJwt from "@/auth/jwt/useJwt";
import Ripple from "vue-ripple-directive";
import upload from "@core/components/admin/upload.vue";
export default {
  components: {
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BButton,
    BPagination,
    BFormGroup,
    BImg,
    BFormInput,
    BFormSelect,
    Prism,
    BDropdownItem,
    BDropdown,
    vSelect,
    BForm,
    BRow,
    BCol,
    BFormFile,
    BModal,
    VBModal,
    upload,
    ToastificationContent,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      log: [],
      pageLength: 10,
      total: 0,
      cid: 0,
      typename: "Picture",
      text: "",
      name: "",
      file: null,
      piclist: [],
      content: "",
      showUrl: "",
      desc: "",
      fengmian: "",
      miaosu: "",
      search: "",
      name: "",
      title: "",
      link: "",
      accountid: "",
      dir: false,
      pages: ["10", "20", "40", "100"],
      columns: [
        {
          label: "外部链接名称",
          field: "wechatnick",
        },
        {
          label: "标题",
          field: "wechatid",
        },
        {
          label: "链接地址",
          field: "wechatno",
        },
        {
          label: "创建时间",
          field: "friendcount",
        },
        {
          label: "封面",
          field: "module",
        },
        {
          label: "更新时间",
          field: "nickname",
        },
        {
          label: "操作",
          field: "action",
        },
      ],
      rows: [],
      searchTerm: "",
    };
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        0: "light-success",
        1: "light-danger",

        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },

    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  created() {
    const user = JSON.parse(localStorage.getItem("userData"));
    this.cid = user.cid;
  },
  methods: {
    getMsgFormSon(data) {
      this.showUrl = data;
      console.log("父控件", this.showUrl);
    },
    timestampToTime(cjsj) {
      const date = new Date(cjsj); // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      const Y = `${date.getFullYear()}-`;
      const M = `${
        date.getMonth() + 1 < 10
          ? `0${date.getMonth() + 1}`
          : date.getMonth() + 1
      }-`;
      const D = `${
        date.getDate() > 9 ? date.getDate() : "0" + date.getDate()
      } `;
      const h = `${
        date.getHours() > 9 ? date.getHours() : "0" + date.getHours()
      }:`;
      const m = `${
        date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes()
      }:`;
      const s =
        date.getSeconds() > 9 ? date.getSeconds() : "0" + date.getSeconds();
      return Y + M + D + h + m + s;
    },

    addlinkmaterial() {
      useJwt
        .linkInsert({
          cid: this.cid,
          did: 0,
          name: this.name,
          miaosu: this.miaosu,
          title: this.title,
          link: this.link,
          fengmian: this.showUrl,
        })
        .then((res) => {
          console.log("提交结果", res);
          if (res.data.code == 0) {
            this.$swal({
              icon: "success",
              title: "添加成功!",
              text: "添加成功",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          }
        });
    },

 
  },
  mounted() {},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.container {
  position: relative;
  width: 200px;
  height: 200px;
  float: left;
  margin-left: 10px;
}
</style>
